import UI from "../../services/UI";

const state = {
  menuLinkText: null,
  menuForServices: null
}

const mutations = {
  SET_MENU_LINK_TEXT: (state, payload) => {
    state.menuLinkText = payload
  },
  SET_MENU_FOR_SERVICES: (state, payload) => {
    state.menuForServices = payload
  }
}

const actions = {
  async getMenuLinkText({commit}) {
    try {
      const response = await UI.getMenuForServices();
      commit('SET_MENU_LINK_TEXT', response.data.data);
    } catch (error) {
      console.log('Vuex: ', error);
    }
  },
  async getMenuServices({commit}) {
    try {
      const response = await UI.getMenuForServices();
      commit('SET_MENU_FOR_SERVICES', response.data.data)
    } catch (error) {
      console.log('Vuex: ', error);
    }
  }
}
const getters = {
  menuLinkText: state => state.menuLinkText,
  menuForServices: state => state.menuForServices
}

export default {
  state,
  mutations,
  actions,
  getters
}
