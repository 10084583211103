<template>
  <div class="blog-page">
    <div class="container">
      <div class="heading_wr">
        <h1 v-if="pageData" class="main_heading"
             data-aos="fade-up"
             data-aos-easing="linear"
             data-aos-delay="100"
             data-aos-duration="500">
          {{ pageData.extra.page_title }}
        </h1>
      </div>
      <div class="blog-page_wr">
        <div class="blog-page_block"
             v-for="post in blogFilter"
             data-aos="fade-up"
             data-aos-easing="linear"
             data-aos-delay="100"
             data-aos-duration="500"
             :key="post.id">
          <router-link :to="`blog/${post.slug}`">
            <img :src="post.preview_image"
                 :alt="post.preview_image_alt">
          </router-link>
<!--          <div class="date">{{ post.updated_at | moment("MMMM DD, YYYY") }}</div>-->
          <h2><router-link :to="`blog/${post.slug}`" class="title">{{ post.title }}</router-link></h2>
          <p>{{ post.preview_text }}</p>
        </div>
      </div>
      <div class="btn_center" v-if="postsToShow <= blogList.length">
        <a class="red_btn" @click="addMorePosts">{{ pageData.extra.button_text }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Vue from "vue";
Vue.use(require('vue-moment'));

export default {
    metaInfo(){
        return {
            title: this.postInfo ? this.postInfo.page_data.title : 'Blog | DigitalBeautyWork',
            titleTemplate: this.postInfo ? this.postInfo.page_data.title : 'Blog | DigitalBeautyWork',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {property: 'og:title', content: this.postInfo ? this.postInfo.page_data.title : 'Blog | DigitalBeautyWork'},
                /*{name: 'title', content: this.postInfo ? this.postInfo.page_data.title : 'Blog | DigitalBeautyWork'},*/
                {property: 'og:site_name', content: 'Blog'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.digitalbeautywork.com/blog'},
                {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
                {property: 'og:description', content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is blog page'},
                {name: 'description', content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is blog page'}
            ],
            link: [
                {rel: 'canonical', href:  'https://www.digitalbeautywork.com/blog'}
            ]
        }
  },
  name: 'Blog',
  data() {
    return {
      postInfo: null,
      postsToShow: 4,
      pageData: null,
      blogList: []
    };
  },
  computed: {
    blogFilter(){
      return this.blogList.slice(0, this.postsToShow)
    }
  },
  created() {
    axios
      .get('https://back.digitalbeautywork.com/api/blog-posts')
      .then(response => (
          this.blogList = response.data.data.posts,
          this.pageData = response.data.data.page_data,
          this.postInfo = response.data.data
      ));
  },
  methods: {
    addMorePosts(){
      this.postsToShow = this.postsToShow += 2
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
@import "src/assets/scss/pages/blog";
</style>
