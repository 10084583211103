import Api from './Api'

export default {
  getLinkText() {
    return Api().get('api/menu');
  },
  getMenuForServices() {
    return Api().get('api/menu/v2');
  },
  getHomePageImg() {
    return Api().get('api/homepage/v2');
  },
  getHomeContent() {
    return Api().get('api/homepage');
  },
  getRealContent() {
    return Api().get('api/reel');
  },
  getAboutPage() {
    return Api().get('api/about');
  },
  getTrustedBrands() {
    return Api().get('api/trusted-brands');
  },
  getServicesPageBySlug(slug) {
    return Api().get(`api/services/by-slug?slug=${slug}`);
  },
}
