<template>
  <div class="notFound container">
    <div class="main_heading">Sorry, this page not found</div>
    <router-link to="/" class="red_btn">Back to home</router-link>
  </div>
</template>


<script>

export default {
  name: 'NotFound'
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
@import "src/assets/scss/pages/notfound";

.notFound{
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
</style>