import UI from "../../services/UI";

const state = {
  real: null
}

const mutations = {
  SET_REAL: (state, payload) => {
    state.real = payload
  }
}

const actions = {
  async getRealContent({commit}) {
    const response = await UI.getRealContent();
    commit('SET_REAL', response.data.data);
  }
}
const getters = {
  realPage: state => state.real
}

export default {
  state,
  mutations,
  actions,
  getters
}
