import UI from "../../services/UI";

const state = {
  aboutPageContent: null
}

const mutations = {
  SET_ABOUT_PAGE_CONTENT: (state, payload) => {
    state.aboutPageContent = payload
  }
}

const actions = {
  async getAboutPageContent({commit}) {
    const res = UI.getAboutPage();
    commit('SET_ABOUT_PAGE_CONTENT', res.data.data);
  }
}
const getters = {
  aboutPageContent: state => state.aboutPageContent
}

export default {
  state,
  mutations,
  actions,
  getters
}
