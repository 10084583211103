import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/Menu'
import HomePage from "./modules/HomePage"
import RealPage from "./modules/RealPage";
import AboutPage from "./modules/AboutPage";
import Services from "./modules/ServicesPage";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu,
    HomePage,
    RealPage,
    AboutPage,
    Services
  }
})
