<template>
  <div>
    <div class="works-page">
      <div class="container">
        <div class="works-page_top"
             data-aos="fade-in"
             data-aos-easing="linear"
             data-aos-duration="500">
          <h1 class="main_heading">SEE OUR WORK</h1>
          <div  class="tab_links">
            <h2 class="tab"
                :class="{ activeTab: +selectedTab === +tab.id }"
                v-for="tab in sortedTabs"
                :key="tab.id"
                @click="changeTab(tab.id)">
              {{ tab.name }}
            </h2>
          </div>
        </div>
        <div class="tab_content">
          <div class="tab_content_item"
               v-for="(video, index) in videoFilter"
               data-aos="fade-up"
               data-aos-easing="linear"
               @click="showVideoPopup(video)"
               :data-aos-delay="video.delay"
               data-aos-duration="500"
               :key="index">
            <div class="tab_content_item_wr" v-if="video.link">
              <img :src="video.image_preview"
                   :alt="video.video_alt"
                   v-if="video.image_preview"
                   loading="lazy"
              >
              <video tabindex="0" autobuffer="autobuffer" playsinline autoplay muted loop class="video" preload="preload">
                <source type="video/mp4;" :src="video.video"/>
              </video>
            </div>
            <h3 class="title" v-if="video.link || video.image">{{ video.title }}</h3>
            <!--   for img -->
<!--            <div class="tab_content_item_wr only_img" v-if="video.video">
              <img :src="video.image_preview"
                   :alt="video.video_alt"
                   loading="lazy"
              >
            </div>-->
          </div>
<!--          <div class="btn_center" v-if="videoToShow <= projects.length">
            <a class="red_btn" @click="addMoreVideo">SEE MORE</a>
          </div>-->
        </div>
      </div>
      <div class="red_blob">
        <img src="@/assets/img/red_blob.png" alt="red_blob" loading="lazy">
      </div>
  </div>
    <modal-video-you-tube v-if="$route.params.slug" :video-id="videoId" :image-full="imageFull" />
</div>
</template>

<script>

  import axios from "axios";
  import Vue from 'vue'
/*
  import VueYouTubeEmbed from 'vue-youtube-embed'
*/
  const VueYouTubeEmbed = () => import('vue-youtube-embed');

/*
  import ModalVideoYouTube from "../components/ModalVideoYouTube";
*/
  const ModalVideoYouTube = () => import('../components/ModalVideoYouTube');

  Vue.use(VueYouTubeEmbed);

  export default {
    name: "Reel",
    components: {ModalVideoYouTube},
      metaInfo(){
          return {
              title: this.postInfo ? this.postInfo.page_data.title : 'Reels | DigitalBeautyWork',
              titleTemplate: this.postInfo ? this.postInfo.page_data.title : 'Reels | DigitalBeautyWork',
              htmlAttrs: {
                  lang: 'en',
                  amp: true
              },
              meta: [
                  {property: 'og:title', content: this.postInfo ? this.postInfo.page_data.title :  'Reels | DigitalBeautyWork'},
                  /*{name: 'title', content: this.postInfo ? this.postInfo.page_data.title :  'Reels | DigitalBeautyWork'},*/
                  {property: 'og:site_name', content: 'Reel'},
                  {property: 'og:type', content: 'website'},
                  {property: 'og:url', content: 'https://www.digitalbeautywork.com/reel'},
                  {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
                  {property: 'og:description', content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is reel page'},
                  {name: 'description', content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is reel page'}
              ],
              link: [
                  {rel: 'canonical', href: 'https://www.digitalbeautywork.com/reel'}
              ]
          }
    },
    data() {
      return {
        postInfo: null,
        tabs: null,
        selectedTab: -1,
        delay: 100,
        reels: [],
        showVideo: false,
        videoToShow: 6,
        videoId: '',
        imageFull: {
          link: '',
          alt: '',
        },
      };
    },
    computed: {
      videoFilter(){
        return this.reels ? this.reels.slice(0, this.videoToShow).sort((a, b) => {
          const getYear = (str) => {
            const yearMatch = str.match(/\d{4}/);
            return yearMatch ? parseInt(yearMatch[0]) : new Date().getFullYear();
          };
          const yearA = getYear(a.title);
          const yearB = getYear(b.title);
          return yearB - yearA;
        }) : [];
      },
      sortedTabs() {
        if (this.tabs){
            const videosTab = this.tabs.find(tab => tab.name === "Videos");
            const otherTabs = this.tabs.filter(tab => tab.name !== "Videos");
            otherTabs.sort((a, b) => b.name.localeCompare(a.name));
            return [videosTab, ...otherTabs];
        } else {
          return false
        }
        }

    },
    created() {
      axios
          .get('https://back.digitalbeautywork.com/api/reels/categories')
          .then(response => {
                this.tabs = [{id: -1,name: 'Videos'}];
                this.tabs = this.tabs.concat(response.data.data);
                this.changeTab(this.tabs[0].id)
          });
        this.metaData()
    },

    methods: {
        metaData(){
            axios
                .get('https://back.digitalbeautywork.com/api/reels')
                .then(response => {
                    this.postInfo = response.data.data
                })
        },

      addMoreVideo(){
        this.videoToShow += 6
      },
      showVideoPopup(el) {
        console.log(el, 'gfnnnfnfnfnffngfnfngf' )
        this.$router.push(`/reel/${el.slug}`);
        if(el) {
          if (el.link && el.link === 'string') {
            this.videoId = this.$youtube.getIdFromURL(el.link);
          } else {
            this.videoId = this.$youtube.getIdFromURL(el.link.url);
          }
          console.log(this.videoId)
        }   /*if (el) {
        this.$router.push({ query: {videoid: el.data.video, activeTab: this.selectedTab } })
      }
        else {
          this.imageFull = el.image;
          if (el) {
            this.$router.push({ query: {imageid: el.image.id, activeTab: this.selectedTab }})
          }
        }
        setTimeout(() => this.showVideo = true, 400);*/
      },
      closeVideoPopup(type, pushRoute = true) {
        this.showVideo = false;
        setTimeout(() => this.resetDataPopup(type, pushRoute), 1000);
      },
      resetDataPopup(type, pushRoute = true) {
        if (type === 'video') {
          this.videoId = '';
        } else {
          this.imageFull = {
            link: '',
            alt: '',
          }
        }
        if (pushRoute) {
          this.$router.push({ query: {} })
        }
      },
      changeTab(id){
        this.selectedTab = id
        axios
            .get(`https://back.digitalbeautywork.com/api/reels?${id === -1 ? '' : `category_id=${id}`}`)
            /*.get('https://back.digitalbeautywork.com/api/reels?categories=' + id)*/
            .then(response => (
                this.reels = response.data.data.reels
            ));
      }
    }
}
</script>

<style  lang="scss">
@import "src/assets/scss/style";
@import "../assets/scss/pages/portfolio";
</style>
