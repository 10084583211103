import UI from "../../services/UI";

const state = {
 servicesPageData: null,
 servicesMeta: null,
}

const mutations = {
    SET_SERVICES_CONTENT_BY_SLUG: (state, payload) => {
        state.servicesPageData = payload
    },
    SET_SERVICES_META_BY_SLUG: (state, payload) => {
        state.servicesMeta = payload
    }

}

const actions = {
async getServicesPageBySlug({commit}, payload) {
    const response = await UI.getServicesPageBySlug(payload);
    commit('SET_SERVICES_CONTENT_BY_SLUG', response.data.data.service);
    commit('SET_SERVICES_META_BY_SLUG', response.data.data.page_data);

}
}

const getters = {
    servicesPageData: state => state.servicesPageData,
    servicesMeta: state => state.servicesMeta
}

export default {
    state,
    mutations,
    actions,
    getters
}
