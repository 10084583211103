import UI from "../../services/UI";

const state = {
  homeContentPortfolioHome: null,
  homeContentVideoInfoMob: null,
  homeContentBeatsInfo: null,
  homeContentBannerInfo: null,
  homeContentLoad: false,
  trustedHomeBrands: [],
  homePageImgData: [],
  homeMeta: null,
}

const mutations = {
  SET_HOME_CONTENT_PORTFOLIO_HOME: (state, payload) => {
    state.homeContentPortfolioHome = payload
  },
  SET_HOME_CONTENT_VIDEO_INFO_MOB: (state, payload) => {
    state.homeContentVideoInfoMob = payload
  },
  SET_HOME_CONTENT_BEATS_INFO: (state, payload) => {
    state.homeContentBeatsInfo = payload
  },
  SET_HOME_CONTENT_BANNER_INFO: (state, payload) => {
    state.homeContentBannerInfo = payload
  },
  SET_HOME_CONTENT_LOAD: (state, payload) => {
    state.homeContentLoad = payload
  },
  SET_HOME_TRUSTED_BRANDS: (state, payload) => {
    state.trustedHomeBrands = payload
  },
  SET_HOME_IMAGE_DATA: (state, payload) => {
    state.homePageImgData = payload
  },
  SET_HOME_META: (state, payload) => {
    state.homeMeta = payload

  }
}

const actions = {
  async getHomeContent({commit}) {
    const response = await UI.getHomeContent();
    commit('SET_HOME_CONTENT_PORTFOLIO_HOME', response.data.data.block4);
    commit('SET_HOME_CONTENT_VIDEO_INFO_MOB', response.data.data.block2);
    commit('SET_HOME_CONTENT_BEATS_INFO', response.data.data.block3);
    commit('SET_HOME_CONTENT_BANNER_INFO', response.data.data.block1);
    commit('SET_HOME_META', response.data.data.page_data);

    const {data} = await  UI.getTrustedBrands();
    commit('SET_HOME_TRUSTED_BRANDS', data.data);
    commit('SET_HOME_CONTENT_LOAD', true);
  },
  async setHomeContentLoad({commit}, payload) {
    commit('SET_HOME_CONTENT_LOAD', payload);
  },
  async getHomePageImg({commit}) {
    try {
      const response = await UI.getHomePageImg();
      commit('SET_HOME_IMAGE_DATA', response.data.data)
    } catch (error) {
      console.log('Vuex: ', error);
    }
  }

}

const getters = {
  homeContentPortfolioHome: state => state.homeContentPortfolioHome,
  homeContentVideoInfoMob:  state => state.homeContentVideoInfoMob,
  homeContentBeatsInfo:     state => state.homeContentBeatsInfo,
  homeContentBannerInfo:    state => state.homeContentBannerInfo,
  homeContentLoad:          state => state.homeContentLoad,
  trustedHomeBrands:        state => state.trustedHomeBrands,
  homePageImgData:          state => state.homePageImgData,
  homeMeta:                 state => state.homeMeta,
}

export default {
  state,
  mutations,
  actions,
  getters
}
