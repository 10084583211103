<template>
    <div>
        <div v-if="servicesPageData" class="container__first__blocks">
            <div class="wrapper__title__img">
                <div class="wrapper__first__block">
                    <div class="doing__title product__title">
                        <p class="doing__title__text product__title__text__position">
                            {{ this.servicesPageData.title}}

                        </p>

                        <div class="doing__span__container">
                            <span class="doing__span__one product__span__one"></span>
                            <span class="doing__span__two product__span__two"></span>
                        </div>
                        <div v-html=" this.servicesPageData.description" class="doing__title__text__small__text product__small__text" />

<!--
                        </div>
-->

                    </div>


                </div>
                <div class="img__container">
                    <img class="img__first__block" :src='this.servicesPageData.image' alt="">
                </div>
            </div>
            <a href="https://www.digitalbeautywork.com/reel"  class="border_btn_redisign">
                <div class="wrapper__button">
                    <div class="contact__button">BEAUTY REEL</div>
                </div>
            </a>

        </div>


        <div class="video__grid__container" v-if="this.servicesPageData">
            <div class="router__button__container">
                <router-link :to="{name: 'portfolio'}">

                    <ButtonsRouter
                            class="button__services"
                            :text="'Show more'"
                    />
                </router-link>
            </div>
            <div class="wrapper__video"
                 v-for="(item) in this.servicesPageData.projects"
                 :key="item.slug"
                 @click="showVideoModal(item)"
                 :id="item.id"
            >
              <div class="relative" v-if="item.video">
                <div class="video__wrapper__one">
                    <img src="@/assets/img/play__portfolio__home.png" alt="" class="play">

                </div>
                <div v-if="item.video" class="video__wrapper__two">
                <p  class="video__title">{{ item.title }}</p>
                </div>
                <span v-if="!item.video.link">
              <img
                      class="img__video"
                      :src="item.video.preview_image ? item.video.preview_image : null"
                      :alt="item.video.preview_image">
                </span>

                <span v-else class="video__wrapper">
                <video tabindex="0" autobuffer="autobuffer" autoplay playsinline muted loop class="redisign__video__one"
                       preload="preload">
                  <source type="video/mp4;" :src="item.video.link ? item.video.link : null"/>
                </video>
            </span>
                </div>
<!--              <span class="wrapper__image" v-else-if="item.image">-->
                <img
                    v-else-if="item.image"
                    class="img__video"
                    :src="item.image.link ? item.image.link : null"
                    :alt="item.image.link">
<!--              </span>-->
            </div>

        </div>

        <PageLoader></PageLoader>

        <div class="faq__margin">
            <faq-block
                    :margin-faq="true"
                    :static-accordion="false"
                    :need-show-button="false"
            />
            <span v-if="this.servicesPageData" class="container__acordion">
                    <div class="details__block">

          <accordion v-for="(item, index) in previewFaq" :key="index">
              <accordion-item class="accordion__item">
                  <template slot="accordion-trigger">
                      <h3 class="accordion__title">
                         {{ item.question }}
                      </h3>
                  </template>
                  <template slot="accordion-content">
                      <div class="details__span__container">
                          <span class="details__span__one"></span>
                          <span class="details__span__two"></span>
                      </div>
                      <span>
         {{ item.answer }}
        </span>
                  </template>
              </accordion-item>
          </accordion>

              <div class="show__more__button" @click="showItems()">
                  {{ changeTextButton }}
                  <img src="~@/assets/img/arrow__faq.svg" alt="" class="accordion__trigger__img__button"
                       :class="{'accordion__trigger__img__button__active': opened}">
              </div>
                        </div>
          </span>


        </div>

        <modal-video-you-tube v-if="$route.params.slug" :video-id="videoId" :image-full="imageFull"/>

    </div>

</template>

<script>
/*
import PageLoader from "../components/PageLoader";
*/
const PageLoader = () => import('../components/PageLoader');
const FaqBlock = () => import('../components/FaqBlock');

/*
import FaqBlock from "../components/FaqBlock";
*/
import {mapGetters} from "vuex";
/*
import ButtonsRouter from "../components/Buttons-Router.vue";
*/
const ButtonsRouter = () => import('../components/Buttons-Router.vue');
const ModalVideoYouTube = () => import('./../components/ModalVideoYouTube');

/*
import ModalVideoYouTube from "./../components/ModalVideoYouTube";
*/
const Accordion = () => import('../components/accordion.vue');

/*
import Accordion from "../components/accordion.vue";
*/
const AccordionItem = () => import('../components/accordion-item.vue');

/*
import AccordionItem from "../components/accordion-item.vue";
*/


export default {
    metaInfo(){
        return {
            title: this.servicesMeta ? this.servicesMeta.title : 'Services | DigitalBeautyWork',
            titleTemplate: this.servicesMeta ? this.servicesMeta.title : 'Services | DigitalBeautyWork',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {property: 'og:title', content: this.servicesMeta ? this.servicesMeta.title : 'Services | DigitalBeautyWork'},
                /*{name: 'title', content: this.servicesMeta ? this.servicesMeta.title : 'Services | DigitalBeautyWork'},*/
                {property: 'og:site_name', content: 'Services'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: `https://www.digitalbeautywork.com/services/${this.$route.params.id}`},
                {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
                {property: 'og:description', content: this.servicesMeta ? this.servicesMeta['meta-content'] : 'This is blog page'},
                {name: 'description', content: this.servicesMeta ? this.servicesMeta['meta-content'] : 'This is blog page'}
            ],
            link: [
                {rel: 'canonical', href: `https://www.digitalbeautywork.com/services/${this.$route.params.id}`}
            ]
        }
    },

    name: "Services",
    components: {
        AccordionItem, Accordion,
        ButtonsRouter,
        PageLoader,
        FaqBlock,
        ModalVideoYouTube,


    },
    data() {
        return {
            previewFaq: [],
            videoId: '',
            imageFull: {
                link: '',
                alt: '',
            },
            opened: false,
            changeTextButton: 'Show more',
            showMoreAccordions: false,


        }
    },
    computed: mapGetters(['servicesPageData', 'servicesMeta']),
    created() {
        this.$store.dispatch('getServicesPageBySlug', this.$route.params.id).then(() => {
            if (this.$route.params.slug) {
                const project = this.servicesPageData.projects.find((item) => item.slug === this.$route.params.slug)
                if (project) {
                    this.showVideoModal(project)
                }
            }
            if (window.innerWidth >= 981) {
                this.previewFaq = this.servicesPageData.faq.slice(0, 4)
            } else {
                this.previewFaq = this.servicesPageData.faq.slice(0, 3)
                /*this.previewFaq = this.servicesPageData.faq*/
            }
        })
    },
    watch: {
        '$route.params.id': function () {
            this.$store.dispatch('getServicesPageBySlug', this.$route.params.id).then(() => {
                console.log(this.$route.params.id, 'hbdfjdbvkdjbfvdbbvdv')

            })
        },


    },
    mounted() {
/*
        if (window.innerWidth >= 981) {
*/
            this.showMoreAccordions = true
        /*}*/

    },
    methods: {
        showVideoModal(item) {
            this.$router.replace(`/services/${this.$route.params.id}/${item.slug}`);
            if (item) {
                this.videoId = this.$youtube.getIdFromURL(item.link)
            } else {
                this.imageFull = item.video.preview_image;
            }
        },
        showItems() {

            this.opened = !this.opened;
            if (!this.opened) {
                this.previewFaq = this.servicesPageData.faq.slice(0, 3);
                this.changeTextButton = 'Show more';
            } else {
                this.previewFaq = this.servicesPageData.faq
                this.changeTextButton = 'Show less';
            }
        },
    },
}
</script>

<style scoped>
.relative{
  position: relative;
  width: 100%;
  height: 100%;
}
.wrapper__video{
  width: 40.365vw;
  height: 23vw;
}
.redisign__video__one {
    width: 100%;
}

/*.show__more__button {
    display: none;
}*/

.container__acordion {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.details__block {
    padding-right: 10.156vw;
    width: 50.625vw;
    height: max-content;
    margin-bottom: 10vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.accordion__item {
    margin-bottom: 2.083vw;
    border-top: 0.052vw solid #FFFFFF;
    border-left: 0.052vw solid #FFFFFF;
    border-bottom: 0.052vw solid #FFFFFF;
    border-right: 0.052vw solid #FFFFFF;
}

.accordion__title {
    font-family: 'Benzin-Regular';
    color: #F5E9E9;
    font-size: 1.354vw;
    padding-right: 3.750vw;
    line-height: 1.563vw;
    padding-left: 1.563vw;
}

.details__span__container {
    margin-top: 1.302vw;
    margin-bottom: 1.302vw;
    display: flex;
    width: 20.885vw;
    height: 0.156vw;
}

.details__span__two {
    height: 0.156vw;
    width: 10.313vw;
    background: rgba(255, 255, 255, 1);
}

.details__span__one {
    height: 0.156vw;
    width: 10.313vw;
    background: rgba(0, 139, 72, 1);
}

.play {
    width: 3.281vw;
  cursor: pointer;
}

.video__wrapper__one {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
}

.img__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.wrapper__video {
    width: 40.365vw;
    position: relative;
    cursor: pointer;
}*/

.button__services {
    width: 21.771vw;
}

.video__grid__container {
    margin-top: 9.375vw;
    width: calc(100% - 16.6vw);
    padding-left: 8.333vw;
    padding-right: 8.333vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2.604vw;
    grid-row-gap: 2.604vw;

}

.router__button__container {
    grid-column: 1;
    grid-row: 2;
}

.wrapper__button {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: max-content;
}

.wrapper__title__img {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: max-content;
}

.doing__title .product__title {
    width: 25.677vw;
    height: max-content;

}

.img__container {
    width: 40.885vw;
}

.img__first__block {
    width: 100%;
}

.container__first__blocks {
    height: max-content;
    display: flex;
    width: calc(100% - 16.6vw);
    padding-top: 10.625vw;
    padding-left: 8.333vw;
    padding-right: 8.333vw;
    flex-direction: column;

}

.wrapper__first__block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 9.115vw;


}

.contact__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15.937vw;
    height: 3.906vw;
    background-color: #DB1023;
    font-family: 'Benzin-Bold';
    font-size: 0.833vw;
    line-height: 0.990vw;
    color: #FFFFFF;
    transition: 0.5s;
}

.contact__button:hover {
    color: #DB1023;
    background-color: #FFFFFF;
}

.doing__title {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 24.531vw;
    height: 15.469vw;
    margin-bottom: 3.177vw;

}

.doing__title__text {
    width: 100%;
    height: max-content;
    text-align: right;
    font-size: 2.083vw;
    font-family: 'Benzin-ExtraBold';
    line-height: 2.448vw;
    color: #FFFFFF;
    margin-bottom: 1.927vw;
}

.doing__title__text__small__text {
    font-size: 0.938vw !important;
    font-family: 'Benzin-Regular';
    line-height: 100%;
    text-align: right;
    color: #FFFFFF;
}


.doing__span__container {
    display: flex;
    width: 20.885vw;
    height: 0.156vw;
    margin-bottom: 2.604vw;
}

.doing__span__one {
    height: 0.156vw;
    width: 10.313vw;
    background: rgba(255, 255, 255, 1);
}

.doing__span__two {
    height: 0.156vw;
    width: 10.313vw;
    background: rgba(0, 139, 72, 1);
}

.product__title {

    width: 25.677vw;
    height: max-content;
    align-items: flex-start;
}

.product__span__one {
    background: rgba(219, 16, 35, 1);
}

.product__span__two {
    background: rgba(255, 255, 255, 1);
}

.product__title__text__position {
    text-align: left;
}

.product__small__text {
    text-align: left;
    line-height: 140%;
}

.faq__margin {
    margin-top: 20vw;
}
.accordion__trigger__img__button__active {
    transform: rotate(180deg);
    transition: 0.5s;
}
.video__wrapper__two{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 91%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .video__wrapper__two {
    top: 85%;
  }
    .container__acordion {
        width: calc(100% - 20vw);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10vw;
        padding-left: 10vw;
    }

    .details__block {
        width: 100%;
        padding: 0;

    }

    .details__span__container {
        margin-bottom: 5.333vw;
        display: flex;
        width: 40.000vw;
        height: 0.267vw;
    }

    .accordion__item {
        margin-bottom: 8.000vw;
        border-top: 0.267vw solid #FFFFFF;
        border-left: 0.267vw solid #FFFFFF;
        border-bottom: 0.267vw solid #FFFFFF;
        border-right: 0.267vw solid #FFFFFF;
    }

    .accordion__title {
        font-family: 'Benzin-Regular';
        color: #F5E9E9;
        font-size: 4.000vw;
        line-height: 4.800vw;
        padding-left: 5.333vw;
        padding-right: 6.133vw;
    }

    .details__span__one {
        height: 0.267vw;
        width: 19.867vw;
    }

    .details__span__two {

    }

    .img__container {
        width: 100%;
    }

    .container__first__blocks {
        padding-top: 27.8vw;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .wrapper__title__img {
        flex-direction: column-reverse;
    }

    .doing__title__text {
        font-size: 5.333vw;
    }

    .product__title {
        width: 68.533vw;
        line-height: 100%;
        margin-bottom: 6.667vw;
        margin-left: 6.400vw;
    }

    .doing__title__text {
        width: 68.533vw;
        line-height: 100%;
        margin-bottom: 6.667vw;
    }

    .product__span__one {
        width: 50%;
        height: 0.267vw;
    }

    .product__span__two {
        width: 50%;
        height: 0.267vw;
    }

    .doing__title__text__small__text {
        font-size: 3.733vw!important;
    }

    .doing__span__container {
        width: 50.667vw;
        margin-bottom: 6.667vw;
    }

    .contact__button {
        width: 50.133vw;
        height: 12.533vw;
        font-size: 3.733vw;
        margin-left: 6.400vw;
    }

    .video__grid__container {
        width: calc(100% - 12.26vw);
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        padding-left: 6.133vw;
        padding-right: 6.133vw;

    }

    .play {
        width: 5.067vw;
      cursor: pointer!important;
    }


    .video__title {
        font-size: 3.733vw;
      line-height: 5.4vw;


    }

    .wrapper__video {
        width: 100%;
      height: 50vw;
    }

    .button__services {
        width: 54.667vw;
        margin-top: 0.267vw;
    }

    .router__button__container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .faq__margin {
        margin-top: 24vw;
    }

    .show__more__button {
        left: 41vw;
        position: relative;
        display: block;
        width: 42.667vw;
        height: 6.933vw;
        font-size: 4.800vw;
        font-family: 'Benzin-Regular';
        margin-top: 10.133vw;
        margin-bottom: 22.400vw;


    }

    .accordion__trigger__img__button__active {
        transform: rotate(180deg);
        transition: 0.5s;
    }

}

</style>
