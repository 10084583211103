<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
const Header = () => import('./components/Header.vue');
const Footer = () => import('./components/Footer.vue');
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters([
      'homeContentLoad'
    ])
  },
  created() {
    this.$store.dispatch('getHomeContent');
  }
}
</script>

<style lang="scss">
  body{
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>

