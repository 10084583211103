<template>
  <div class="blog-page-single">
    <div v-if="postInfo" class="container">
      <div class="date">{{ postInfo.post.updated_at | moment("MMMM DD, YYYY") }}</div>
      <h1 class="main_heading">{{ postInfo.post.title }}</h1>
      <div class="body" v-html="postInfo.post.body"></div>

      <div class="post_navigation" :class="{no_prev: !postInfo.previous_post}">
        <div class="prev" v-if="postInfo.previous_post">
          <router-link :to="postInfo.previous_post.slug" class="main_heading">Previous <span>Post</span></router-link>
          <div class="date">{{ postInfo.previous_post.updated_at | moment("MMMM DD, YYYY") }}</div>
          <router-link :to="postInfo.previous_post.slug">{{ postInfo.previous_post.title }}</router-link>
        </div>

        <div class="next" v-if="postInfo.next_post">
          <router-link :to="postInfo.next_post.slug" class="main_heading">Next <span>Post</span></router-link>
          <div class="date">{{ postInfo.next_post.updated_at | moment("MMMM DD, YYYY") }}</div>
          <router-link :to="postInfo.next_post.slug">{{ postInfo.next_post.title }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Vue from "vue";
Vue.use(require('vue-moment'));

export default {

    metaInfo(){
      return {
          title: this.postInfo ? this.postInfo.post.title : 'Blog | DigitalBeautyWork',
          titleTemplate: this.postInfo ? this.postInfo.post.title : 'Blog | DigitalBeautyWork',
          htmlAttrs: {
            lang: 'en',
            amp: true
          },
          meta: [
            {property: 'og:title', content: this.postInfo ? this.postInfo.post.title : 'Blog | DigitalBeautyWork'},
            /*{name: 'title', content: this.postInfo ? this.postInfo.post.title : 'Blog | DigitalBeautyWork'},*/
            {property: 'og:site_name', content: 'Blog single'},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: 'https://www.digitalbeautywork.com/blog'},
            {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
            {property: 'og:description', content: this.postInfo ? this.postInfo.post.meta_content : 'Blog | DigitalBeautyWork'},
            {name: 'description', content: this.postInfo ? this.postInfo.post.meta_content : 'Blog | DigitalBeautyWork'}

          ],
          link: [
            {rel: 'canonical', href: `https://www.digitalbeautywork.com/blog/${this.$route.params.id}`}
          ]
        }


    },
  name: 'BlogSingle',

  data() {
    return {
      postInfo: null
    };
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  created() {
    this.fetchData()
  },

  methods: {
    fetchData(){
      axios
          .get('https://back.digitalbeautywork.com/api/blog-posts/' + this.$route.params.id)
          .then(response => {
            this.postInfo = response.data.data
          }).catch((error) => {
            console.log(error);
            this.$router.push({ path: '/blog' });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/style";
@import "src/assets/scss/pages/blog";
footer{
  padding-top: 400px !important;
}
</style>
