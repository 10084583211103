import axios from "axios";

const api = axios.create({
  baseURL: 'https://back.digitalbeautywork.com/',
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default () => {
  return api;
};
