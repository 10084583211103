import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Portfolio from '../views/Portfolio.vue'
import About from '../views/About.vue'
import Blog from '../views/Blog.vue'
import BlogSingle from '../views/BlogSingle.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsConditions from '../views/TermsConditions.vue'
import NotFound from '../views/NotFound.vue'
import Reel from "@/views/Reel";
import Services from "../views/Services";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home/:slug',
    name: 'home-slug',
    component: Home
  },
  {
    path: '/reel',
    name: 'reel',
    component: Reel
  },
  {
    path: '/reel/:slug',
    name: 'reel-slug',
    component: Reel
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: Portfolio
  },
  {
    path: '/portfolio/:slug',
    name: 'portfolio-slug',
    component: Portfolio
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/blog/:id',
    name: 'single',
    component: BlogSingle
  },
  {
    path: '/services/:id',
    name: 'services',
    component: Services
  },
  {
    path: '/services/:id/:slug',
    name: 'services',
    component: Services
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: TermsConditions
  },
  {
    path: '/404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
