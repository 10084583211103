<template>
  <div class="about-page" id="about-page">
    <div class="blur_blue" id="blur_blue">
      <img src="@/assets/img/blue_blur.png" alt="blue_blur" loading="lazy">
    </div>
    <div v-if="about" class="container_mini">
      <div class="about-page_block"
           v-for="block in about.blocks"
           :key="block.title">
        <div class="main_heading"
             data-aos="fade-up"
             data-aos-easing="linear"
             data-aos-duration="500">{{ block.title }}</div>
        <div class="text_wr"
             data-aos="fade-up"
             data-aos-easing="linear"
             data-aos-duration="500">
          {{ block.description }}
        </div>

        <div class="content_type" v-if="block.media_type === 'video'">
          <div class="video"
               data-aos="fade-up"
               data-aos-easing="linear"
               v-for="(work, index) in block.media"
               :id="index"
               :key="index"
               data-aos-duration="500">
            <video tabindex="0" playsinline muted loop="loop" autoplay="autoplay" preload="auto">
              <source type="video/mp4;" :src="work.link"/>
            </video>
          </div>
        </div>
        <div class="gallery" v-else>
          <div class="item"
               v-for="(work, index) in block.media"
               :key="index"
               data-aos="fade-up"
               data-aos-easing="linear"
               data-aos-duration="500">
            <img
              :src="work.link"
              :alt="work.alt"
              loading="lazy"
            >
          </div>
        </div>

        <div class="text_wr"
             data-aos="fade-up"
             data-aos-easing="linear"
             data-aos-duration="500">
          {{ block.media_description }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import gsap from "gsap";
import { mapGetters } from 'vuex'
import axios from "axios";
Vue.use(gsap);


export default {
    metaInfo(){
        return {
            title: this.about ? this.about.page_data.title : 'About Us | DigitalBeautyWork2',
            titleTemplate: this.about ? this.about.page_data.title : 'About Us | DigitalBeautyWork2',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {
                    property: 'og:title',
                    content: this.about ? this.about.page_data.title : "About Us | DigitalBeautyWork"
                },
                /*{
                    name: 'title',
                    content: this.about ? this.about.page_data.title : "About Us | DigitalBeautyWork"
                },*/
                {property: 'og:site_name', content: "About"},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.digitalbeautywork.com/about'},
                {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
                {
                    property: 'og:description',
                    content: this.about ? this.about.page_data['meta-content'] : 'This is about page'
                },
                {
                    name: 'description',
                    content: this.about ? this.about.page_data['meta-content'] : 'This is about page'
                }

            ],
            link: [
                {rel: 'canonical', href: 'https://www.digitalbeautywork.com/about'}
            ]
        }
  },
  name: 'About',
  data() {
    return {
      about: null
    };
  },
  computed: {
    ...mapGetters([
      'aboutPageContent'
    ])
  },

  created() {
    // this.$store.dispatch('getAboutPageContent').then(() => {
    //   this.about = this.aboutPageContent;
    // });

    axios
      .get('https://back.digitalbeautywork.com/api/about')
      .then(response => (
        this.about = response.data.data
      ));

  },
  updated() {
    const blur = this.$scrollmagic.scene({
      triggerElement: '#about-page',
      triggerHook: 0.3,
      duration: '100%'
    })
      .setTween('#blur_blue', {
        css: {
          transform: 'translate(80%, 30%)'
        }
      })
    this.$scrollmagic.addScene(blur)
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
@import "../assets/scss/pages/about";
</style>
