<template>
  <div class="infoPage container">
    <div class="main_heading">Terms & Conditions</div>
    <div class="second_heading">1. Purpose</div>
    <p>Digital Beauty Work holds and processes personal data on behalf of its staff and clients, a valuable asset that needs to be suitably protected. Every care is taken to protect personal data from incidents (either accidental or deliberate) to avoid a security breach that could compromise data. Compromise of information, confidentiality, integrity, or availability may result in harm to individuals, reputational damage, detrimental effect on service provision, legislative noncompliance, and/or financial costs including significant fines from the Information Commissioner’s Office (ICO).</p>
    <p>The company is obliged under the Data Protection Act to have in place systems designed to ensure the security of all personal data during its lifecycle, including clear lines of responsibility. This Policy sets out the procedure to be followed to ensure a consistent and effective approach is in place for managing data breach and information security incidents.</p>
    <p>The makes notification mandatory for all controllers unless a breach is unlikely to result in a risk to the rights and freedoms of individuals. Processors must notify any breach to their controllers. Controllers and processors are therefore encouraged to put in place processes to be able to detect and promptly contain a breach, to assess the risk to individuals, and then to determine whether it is necessary to notify the competent supervisory authority, and to communicate the breach to the individuals concerned when necessary.</p>
    <div class="second_heading">2. Scope</div>
    <p>This Policy relates to all personal and sensitive data controlled or processed by the company regardless of format.</p>
    <p>This Policy applies to all employees, contractors, consultants, temporary staff, and other workers at Digital Beauty Work and data processors working for, or on behalf of the company.</p>
    <div class="second_heading">2.1. Types of Personal Data Breaches</div>
    <div class="second_heading">2.1.1. Confidentiality breach</div>
    <p>Where there is an unauthorised or accidental disclosure of, or access to, personal data.</p>
    <p>For Example:</p>
    <ul>
      <li>personal data accidentally being sent to someone (either internally or externally) who does not have a legitimate need to see it;</li>
      <li>client database being compromised, for example being accessed by another client;</li>
      <li>paper records containing personal data being left unprotected for anyone to see, for example: files left out when the owner is away from their desk and at the end of the day, papers not properly disposed of in confidential shredding bins, papers left at printers;</li>
      <li>staff accessing or disclosing personal data outside the requirements or authorisation of their job;</li>
      <li>being deceived by a third party into improperly releasing the personal data of another person;</li>
    </ul>
    <div class="second_heading">2.1.2. Availability breach</div>
    <p>Where there is an accidental or unauthorised loss of access to, or destruction of, personal data.</p>
    <p>For Example:</p>
    <ul>
      <li>loss or theft of laptops, mobile devices, or paper records containing personal data;</li>
      <li>the loss of personal data due to unforeseen circumstances such as a fire or flood;</li>
      <li>when there has been a permanent loss of, or destruction of, personal data;</li>
    </ul>
    <div class="second_heading">2.1.3. Integrity breach</div>
    <p> Where there is an unauthorised or accidental alteration of personal data.</p>
    <p>For Example:</p>
    <ul>
      <li>The removal or false alteration of individuals’ mobile numbers or email addresses;</li>
    </ul>
    <p>It should also be noted that, depending on the circumstances, a breach can concern confidentiality, availability and integrity of personal data at the same time, as well as any combination of these.</p>
    <div class="second_heading">3. Policy</div>
    <p>On discovery of a data breach the following actions should be taken:</p>
    <ul>
      <li>Containment and recovery;</li>
      <li>Assessing the risk;</li>
      <li>Notification of breach to the Information Commissioner’s Office (ICO);</li>
      <li>Evaluation and response;</li>
    </ul>
    <div class="second_heading">3.1. Containment and Recovery</div>
    <p>The individual committing the breach or having identified a possible breach should immediately inform their manager or the Information Security Officer.</p>
    <p>The immediate priority is to contain the breach and limit its scope and impact.</p>
    <ul>
      <li>Where personal data has been seen, accessed or been sent to someone who does not have a legitimate need to see it, staff should contact the recipient and;</li>
      <li>tell the recipient not to pass it on or discuss it with anyone else;</li>
      <li>tell the recipient to destroy or delete the personal data they have received and get them to confirm in writing that they have done so;</li>
      <li>warn the recipient of any implications if they further disclose the data;</li>
      <li>Where data has been lost, altered or has become unavailable, then access to the data should be resumed as quickly as possible via backup copies of the data if necessary;</li>
      <li>Where the data controller is a Digital Beauty Work’s client, the client’s Data Protection Officer or person responsible for receiving breach notifications is to be given an initial notification stating what recovery processes are being performed with further information about the breach provided in phases as information becomes available. This is important in order to help the controller to meet the requirement of notification to the supervisory authority within 72 hours;</li>
    </ul>
    <p>A Breach Notification incident should be logged on the Internal IT Support system stating:</p>
    <ul>
      <li>date and time of the breach;</li>
      <li>date and time breach detected;</li>
      <li>who committed the breach;</li>
      <li>details of the breach;</li>
      <li>number of data subjects involved (an approximation is sufficient);</li>
      <li>details of actions already taken in relation to the containment and recovery.</li>
    </ul>
    <div class="second_heading">3.2. Assessing the Risk</div>
    <p>The Information Security Officer or Data Protection Officer or a nominated person will conduct an investigation into the breach and prepare a Breach Report.</p>
    <p>This report will follow the ICO’s guidance on Breach Management and will consider the following:</p>
    <ul>
      <li>How the breach occurred;</li>
      <li>The type of personal data involved;</li>
      <li>The number of data subjects affected by the breach;</li>
      <li>Who the data subjects are;</li>
      <li>The sensitivity of the data breached;</li>
      <li>What harm to the data subjects can arise? For example, the breach could result in identity theft or fraud, physical harm, psychological distress, humiliation or damage to reputation;</li>
      <li>What could happen if the personal data is used inappropriately or illegally;</li>
      <li>For personal data that has been lost or stolen, are there any protections in place such as encryption;</li>
      <li>The measures taken or proposed to be taken to address the personal data breach, including, where appropriate, measures to mitigate its possible adverse effects;</li>
      <li>Whether the breach should be notified to the ICO – if NOT the reasoning behind this decision including reasons why the breach is unlikely to result in a risk to the rights and freedoms of individuals;</li>
    </ul>
    <div class="second_heading">3.3. Breach notification</div>
    <div class="second_heading">3.3.1. To the Information Commissioner’s Office (ICO)</div>
    <p>Under Article 33 of the GDPR - In the case of a personal data breach, the controller shall without undue delay and, where feasible, not later than 72 hours after having become aware of it, notify the personal data breach to the supervisory authority competent in accordance with Article 55, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons. Where the notification to the supervisory authority is not made within 72 hours, it shall be accompanied by reasons for the delay.</p>
    <p>The Data Protection Officer or information Security Officer or in the absence of either of these people, any member of the Senior Leadership Team, will determine whether the breach is one which is required to be notified to the ICO.</p>
    <p>NOTE: Where the data breach involves any client data either hosted by Digital Beauty Work or remotely accessed by Digital Beauty Work staff the responsibility for reporting the breach is with the controller(s) ie. The clients’ Data Protection Officers or person responsible for breach notifications.</p>
    <p>When notifying a breach to the ICO include the Breach Report with the name and contact details of the Data Protection Officer or other contact point where more information can be obtained;</p>
    <div class="second_heading">3.3.2. To the affected individuals/companies</div>
    <p>If a breach is also assessed to be likely to result in a high risk to the rights and freedoms of individuals/companies interest, the individuals/companies themselves must be informed directly and without undue delay, particularly if there is a need to mitigate an immediate risk of damage to them. One of the main reasons for informing individuals/companies is to help them take steps to protect themselves from the effects of a breach.</p>
    <p>When informing the individuals/companies the following needs to be supplied in clear and plain language:</p>
    <ul>
      <li>the nature of the data breach;</li>
      <li>the name and contact details of the Data Protection Officer or other contact point where more information can be obtained;</li>
      <li>a description of the likely consequences of the data breach; and</li>
      <li>a description of the measures taken, or proposed to be taken, to deal with the data breach and including, where appropriate, of the measures taken to mitigate any possible adverse effects;</li>
    </ul>
    <div class="second_heading">3.4. Evaluation and response</div>
    <p>Once the breach has been dealt with the cause of the breach needs to be considered. There may be a need to update policies and procedures, or to conduct additional training.</p>
  </div>
</template>


<script>

export default {
  name: 'TermsConditions'
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
</style>
