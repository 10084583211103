<template>
  <div class="works-page">
    <div class="container">
      <div class="works-page_top"
           data-aos="fade-in"
           data-aos-easing="linear"
           data-aos-duration="500"
      >
        <h1 class="main_heading">SEE OUR WORK</h1>
        <div class="tab_links">
          <h2 class="tab"
              :class="{ activeTab: selectedTab === tab.id }"
              v-for="tab in tabs"
              :key="tab.id"
              @click="changeTab(tab.id)">
            {{ tab.name }}
          </h2>
        </div>
      </div>
      <div class="works-page__works-container">
        <template
            v-if="tabs.length > 0 && sliderProjects.length > 0"
        >
          <div
              v-for="subCategory in sliderProjects"
              :key="subCategory.category"
              class=""
          >
            <template v-if="subCategory.items.length > 0">
              <div
                  v-if="subCategory.category"
                  class="works-page__carousel__header"
              >
                <h3 class="works-page__carousel__head">{{ subCategory.category }}</h3>
              </div>
              <div
                  class="works-page__carousel"
                  v-for="(proj,index) in subCategory.items"
                  :key="index"
              >

                <div class="tab_content_item"
                     v-for="(video) in proj"
                     @click="showVideoPopup(video)"
                     data-aos="fade-up"
                     data-aos-easing="linear"
                     :data-aos-delay="video.delay"
                     data-aos-duration="500"
                     :key="video.id">
                  <div class="tab_content_item_wr" v-if="video.video">
                    <img :src="video.video.preview_image"
                         :alt="video.video.alt"
                         loading="lazy"
                    >
                    <video tabindex="0" autobuffer="autobuffer" playsinline autoplay muted loop class="video"
                           preload="preload">
                      <source type="video/mp4;" :src="video.video.link"/>
                    </video>
                  </div>
                  <h3 class="title" v-if="video.video">{{ video.video.title }}</h3>
                  <div class="tab_content_item_wr only_img" v-if="video.image">
                    <img :src="video.image.link"
                         :alt="video.image.alt"
                         loading="lazy"
                    >
                  </div>
                  <h3 class="title" v-if="video.image">{{ video.title }}</h3>
                </div>
              </div>
              <div class="btn_center" v-if="subCategory.currentPage < subCategory.pagesTotal">
                <a class="red_btn" @click="addMoreVideo(subCategory.id, subCategory.currentPage+=1)">SEE MORE</a>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="red_blob">
      <img src="@/assets/img/red_blob.png" alt="red_blob" loading="lazy">
    </div>
    <modal-video-you-tube v-if="$route.params.slug" :video-id="videoId" :image-full="imageFull"/>
  </div>
</template>


<script>
import axios from "axios";
import Vue from 'vue'

const VueYouTubeEmbed = () => import('vue-youtube-embed');
const ModalVideoYouTube = () => import('../components/ModalVideoYouTube');

Vue.use(VueYouTubeEmbed)

export default {
  components: {ModalVideoYouTube},

  metaInfo() {
    return {
      title: this.postInfo ? this.postInfo.page_data.title : 'Portfolio | DigitalBeautyWork',
      titleTemplate: this.postInfo ? this.postInfo.page_data.title : 'Portfolio | DigitalBeautyWork',
      htmlAttrs: {
        lang: 'en',
        amp: true
      },
      meta: [
        {
          property: 'og:title',
          content: this.postInfo ? this.postInfo.page_data.title : 'Portfolio | DigitalBeautyWork'
        },
        {property: 'og:site_name', content: 'Portfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: 'https://www.digitalbeautywork.com/portfolio'},
        {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
        {
          property: 'og:description',
          content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is portfolio page'
        },
        {
          name: 'description',
          content: this.postInfo ? this.postInfo.page_data['meta-content'] : 'This is portfolio page'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.$route.params.slug ? `https://www.digitalbeautywork.com/portfolio/${this.$route.params.slug}` : 'https://www.digitalbeautywork.com/portfolio'
        }
      ]
    }
  },
  name: 'Portfolio',
  data() {
    return {
      postInfo: null,
      tabs: [],
      selectedTab: 1,
      delay: 100,
      projects: [],
      showVideo: false,
      videoToShow: 6,
      videoId: '',
      sliderProjects: [],
      imageFull: {
        link: '',
        alt: '',
      },
    };
  },
  created() {
    console.log(window.innerWidth)
    this.selectedTab = this.$route.query.activeTab ? +this.$route.query.activeTab : 1;
    axios
        .get('https://back.digitalbeautywork.com/api/projects/categories/v2')
        .then(response => {
          this.tabs = response.data.data;
          this.metaData()
          this.onInitTab()
        });
  },
  methods: {
    onInitTab() {
      const currTab = this.tabs.find(tab => tab.id === this.selectedTab)
      if (currTab.sub_categories) {
        this.sliderProjects = currTab.sub_categories.map(category => ({
          category: category.name, items: [], pagesTotal: 1, currentPage: 1, id: 0
        }))
        currTab.sub_categories.forEach((category) => {
          axios
              .get(
                  this.getLinkForCategory(category.id, 1)
              )
              .then(({data}) => {
                console.log(data)
                if (data.data.projects.length > 0) {
                  const categoryObject = this.sliderProjects.find(categoryObj => categoryObj.category === category.name)
                  categoryObject.items = [data.data.projects]
                  categoryObject.pagesTotal = data.data.pages_total
                  categoryObject.id = category.id
                }
              })
        })
      } else {
        axios
            .get(this.getLinkForCategory(this.selectedTab, 1))
            .then(response => {
              const projects = response.data.data.projects
              this.projects = projects;
              this.sliderProjects = [{
                items: [projects], pagesTotal: response.data.data.pages_total, currentPage: 1, id: this.selectedTab
              }]
            })
      }
    },
    getLinkForCategory(id, page) {
      return `https://back.digitalbeautywork.com/api/projects?category_id=${id}&page=${page}&per_page=3`
    },
    metaData() {
      axios
          .get('https://back.digitalbeautywork.com/api/projects?category_id=' + this.selectedTab)
          .then(response => {
            this.postInfo = response.data.data
          })
    },
    addMoreVideo(id, page) {
      axios
          .get(
              this.getLinkForCategory(id, page)
          )
          .then(({data}) => {
            console.log(data)
            if (data.data.projects.length > 0) {
              const categoryObject = this.sliderProjects.find(categoryObj => categoryObj.id === id)
              categoryObject.items = [...categoryObject.items, data.data.projects]
            }
          })
    },
    showVideoPopup(el) {
      this.$router.push(`/portfolio/${el.slug}`);
      if (el) {
        this.videoId = this.$youtube.getIdFromURL(el.link);
      } else {
        this.imageFull = el.image;
        if (el) {
          this.$router.push({query: {imageid: el.image.id, activeTab: this.selectedTab}})
        }
      }
      setTimeout(() => this.showVideo = true, 400);
    },
    closeVideoPopup(type, pushRoute = true) {
      this.showVideo = false;
      setTimeout(() => this.resetDataPopup(type, pushRoute), 1000);
    },
    resetDataPopup(type, pushRoute = true) {
      if (type === 'video') {
        this.videoId = '';
      } else {
        this.imageFull = {
          link: '',
          alt: '',
        }
      }
      if (pushRoute) {
        this.$router.push({query: {}})
      }
    },
    changeTab(id) {
      this.selectedTab = id
      this.sliderProjects = []
      this.onInitTab()
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
@import "../assets/scss/pages/portfolio";
</style>
