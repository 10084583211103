<template>
    <div class="home-page" id="home-page">

        <PageLoader></PageLoader>

        <div v-if="bannerInfo" class="first__block__container">
          <img class="first__block__container_image_right_background" src='https://dbwImages.b-cdn.net/Background1.svg' alt="homeFirstBlockBackground">


          <div class="main__text__first__block">
              <h1 class="studio__big__text">
                BOUTIQUE <br/>
                RETOUCHING <br/>
                STUDIO
              </h1>

                <p class="studio__small__text">Transform any visual content into<br>
                    exceptional imagery</p>
                <div class="showreel__button" @click="fullscreenReel(true)">{{ bannerInfo.button_text }}</div>
            </div>
            <div class="main__text__first__block__mobile">
                <div class="main__text__container__mobile">
                    <img src="@/assets/img/studio__home__mobile.svg" alt="" class="studio__home__mobile">
                    <p class="studio__small__text__mobile">Transform any visual content into<br>
                        exceptional imagery</p>
                </div>
                <div class="showreel__button__mobile" @click="fullscreenReel(true)">{{ bannerInfo.button_text }}</div>
            </div>


            <div v-if="homePageImg" class="home__video"><img :src="this.homePageImg[0].image" alt=""
                                                             class="home__video__img"></div>
            <router-link class="link__rout" :to="{name: 'portfolio'}">
                <p v-if="homePageImg" class="video__text">{{ this.homePageImg[0].title }}</p>


            </router-link>

            <div class="home__photo" v-if="homePageImg"><img :src="this.homePageImg[1].image" alt=""
                                                             class="home__photo__img"></div>
            <!--        src="@/assets/img/home__photo.png"-->
            <router-link class="link__rout" :to="{name: 'portfolio'}">

                <p v-if="homePageImg" class="photo__text">{{ this.homePageImg[1].title }}</p>

            </router-link>


            <div class="home__clean" v-if="homePageImg"><img :src="this.homePageImg[2].image" alt=""
                                                             class="home__clean__img"></div>
            <router-link class="link__rout" :to="{name: 'portfolio'}">

                <p v-if="homePageImg" class="clean__text">{{ this.homePageImg[2].title }}</p>

            </router-link>


            <a v-scroll-to="{ el: '#contact', duration: 1000 }">

                <div class="button__home__firs__block">
                    <ButtonsRouter
                            :text="'Contact us'"
                    />

                </div>
            </a>

            <div class="footer__social__first__block">
                <a href="https://www.instagram.com/digitalbeautywork/"><img class="footer__social__links__first__block"
                                                                            src="@/assets/img/instagramm.png"
                                                                            alt="instagram"></a>
                <a href="https://www.linkedin.com/company/digital-beauty-work/"><img
                        class="footer__social__links__first__block"
                        src="@/assets/img/linkidin.png"
                        alt="linkedin"></a>
                <a href="https://www.facebook.com/digitalbeautywork"><img
                        class="footer__social__links__first__block__facebook"
                        src="@/assets/img/facebook.png" alt="facebook"></a>
            </div>


            <VueSlickCarousel

                    v-bind="settings"
                    :arrows="true"
                    :dots="true"
            >
                <router-link :to="{name: 'portfolio'}">
                    <div v-if="homePageImg" class="item__slider__container__video">
                        <img :src="this.homePageImg[0]['mobile_image']  ? this.homePageImg[0]['mobile_image'] : this.homePageImg[0].image" alt="" class="home__video__img__mobile">
                        <span v-if="homePageImg" class="test__one">{{ this.homePageImg[0].title }}</span>
                    </div>
                </router-link>

                <router-link :to="{name: 'portfolio'}">
                    <div v-if="homePageImg" class="item__slider__container__photo">
                        <img :src="this.homePageImg[1]['mobile_image']  ? this.homePageImg[1]['mobile_image'] : this.homePageImg[1].image" alt="" class="home__photo__img__mobile">
                        <span v-if="homePageImg" class="test__two">{{ this.homePageImg[1].title }}</span>

                    </div>
                </router-link>


                <router-link :to="{name: 'portfolio'}">
                    <div v-if="homePageImg" class="item__slider__container__clean">
                        <img :src="this.homePageImg[2]['mobile_image']  ? this.homePageImg[2]['mobile_image'] : this.homePageImg[2].image" alt="" class="home__clean__img__mobile">
                        <span v-if="homePageImg" class="test__three">{{ this.homePageImg[2].title }}</span>

                    </div>
                </router-link>

            </VueSlickCarousel>

        </div>
        <ModalShowReel :show-modal="showReel" @close="fullscreenReel(false)"></ModalShowReel>
        <what-are-we-doing/>
        <PortfolioHomeRedisigne/>
        <faq-block :static-accordion="true" :margin-faq="false" :need-show-button="true"/>
    </div>
</template>

<script>
const ModalShowReel = () => import('../components/ModalShowReel');

import {mapGetters} from 'vuex';
const FaqBlock = () => import('../components/FaqBlock');
const PortfolioHomeRedisigne = () => import('../components/PortfolioHomeRedisigne');
const WhatAreWeDoing = () => import('../components/WhatAreWeDoing');
const VueSlickCarousel = () => import('vue-slick-carousel');

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import jsonld from 'vue-head';

const PageLoader = () => import('../components/PageLoader');
const ButtonsRouter = () => import('../components/Buttons-Router');

export default {
  head() {
    return {
      ...jsonld({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Digital Beauty Work",
        "url": "https://www.digitalbeautywork.com/",
        "logo": "https://dbwimages.b-cdn.net/LogoMobileSvg.svg",
        "sameAs": [
          "https://www.instagram.com/digitalbeautywork/",
          "https://www.facebook.com/digitalbeautywork",
          "https://www.linkedin.com/company/digital-beauty-work/"
        ],
        "contactPoint": {
          "@type": "ContactPoint",
          "email": "beauty@digitalbeautywork.com",
          "contactType": "customer service",
          "areaServed": "USA"
        },
        "founder": {
          "@type": "Person",
          "name": "Max Colt",
          "sameAs": "https://instagram.com/maxcolt"
        }
      })
    };
  },
    metaInfo(){
        return {
            title: this.homeMeta ? this.homeMeta.title :  'Video Retouching Services | DigitalBeautyWork',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {property: 'og:title', content:  this.homeMeta ? this.homeMeta.title : 'Video Retouching Services | DigitalBeautyWork'},
               /* {name: 'title', content:  this.homeMeta ? this.homeMeta.title : 'Video Retouching Services | DigitalBeautyWork'},*/
                {property: 'og:site_name', content: 'Home page'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://www.digitalbeautywork.com/'},
                {property: 'og:image', content: 'http://afirstone.com/img/logo.b4894e81.svg'},
                {property: 'og:description', content:  this.homeMeta ? this.homeMeta['meta-content'] : 'This is homepage'},
                {name: 'description', content:  this.homeMeta ? this.homeMeta['meta-content'] : 'This is homepage'},
            ],
            link: [
                {rel: 'canonical', href: 'https://www.digitalbeautywork.com/'}
            ]
        }
    },
    name: 'Home',
    components: {
        ButtonsRouter,
        PageLoader,
        FaqBlock,
        VueSlickCarousel,
        WhatAreWeDoing,
        ModalShowReel,
        PortfolioHomeRedisigne
    },
    data() {
        return {
            homePageImg: [],
            showReel: false,
            bannerInfo: null,
            settings: {
                "arrows": true,
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        };
    },
    methods: {
        fullscreenReel(show = false) {
            this.showReel = show;
        }
    },
    computed: {
        ...mapGetters([
            'homeContentBannerInfo',
            'homeContentLoad',
            'homePageImgData',
            'homeMeta'
        ])
    },
    created() {
        console.log(this.homePageImgData, 'this.homePageImgData')
        this.$store.dispatch('getHomePageImg').then(() => {
            this.homePageImg = this.homePageImgData;
        });

    },
    mounted() {
        this.bannerInfo = this.homeContentBannerInfo;
    },
    watch: {
        homeContentLoad: function () {
            this.bannerInfo = this.homeContentBannerInfo;
        }
    }
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
@import "src/assets/scss/pages/home";


.link__rout {
  cursor: pointer;
}
.first__block__container_image_right_background{
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
}

.first__block__container {
  position: relative;
  padding-top: 5.625vw;
  overflow: hidden;
  width: 100%;
  height: 70.083vw;
}

.first__block__container::after {
  overflow: hidden;
  content: '';
  width: 100%;
  background-image: url("~@/assets/img/shadow.png");
  background-size: cover;
  height: 7.86vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6;
  -webkit-box-shadow: inset 1px -200px 74px -103px rgba(0, 0, 0, 0.99);
  -moz-box-shadow: inset 1px -200px 74px -103px rgba(0, 0, 0, 0.99);
  box-shadow: inset 1px -200px 74px -103px rgba(0, 0, 0, 0.99);

}


.home__video {
  width: 30.36vw;
  height: 26.25vw;
  position: absolute;
  top: 5.625vw;
  left: 39vw;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__video__img {
  width: 100%;
  transform: rotate(180deg);
}

.home__photo {
  width: 30.36vw;
  height: 26.25vw;

  position: absolute;
  top: 6.625vw;
  left: 55.5vw;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__photo__img {
  width: 100%;
}

.home__clean {
  /*width: 43.333vw;
  height: 37.396vw;*/
  width: 30.36vw;
  height: 26.25vw;

  position: absolute;
  bottom: 15vw;
  left: 55.5vw;
  z-index: 1;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__clean__img {
  width: 100%;
  transform: rotate(180deg);
}


.button__home__firs__block {
  width: 21.771vw;
  height: 3.542vw;
  position: absolute;
  left: 8.333vw;
  bottom: 14vw;
  cursor: pointer;

}

.show__first__block {
  width: 15.052vw;
  height: 3.542vw;
  margin-right: 2.083vw;
}

.arrow__first__block {
  width: 4.635vw;
  height: 1vw;
}

.footer__social__first__block {
  z-index: 10;
  position: absolute;
  bottom: 16vw;
  right: 8.438vw;
  width: 11.458vw;
  height: 1.719vw;
  display: flex;
  justify-content: space-between;
}

.footer__social__links__first__block {
  cursor: pointer;
  width: 1.615vw;
  height: 1.615vw;
}

.footer__social__links__first__block__facebook {
  width: 0.833vw;
  height: 1.615vw;
}

.video__text {
  font-size: 2.083vw;
  font-family: 'Benzin-ExtraBold';
  line-height: 143.9%;
  color: #FFFFFF;
  position: absolute;
  top: 17vw;
  left: 51vw;
  z-index: 2;
}

.photo__text {
  font-size: 2.083vw;
  font-family: 'Benzin-ExtraBold';
  line-height: 143.9%;
  color: #FFFFFF;
  position: absolute;
  top: 17vw;
  left: 67vw;
  z-index: 1;

}

.clean__text {
  font-size: 2.083vw;
  font-family: 'Benzin-ExtraBold';
  line-height: 143.9%;
  color: #FFFFFF;
  position: absolute;
  bottom: 30.5vw;
  right: 23vw;
  z-index: 2;

}

.main__text__first__block {
  position: absolute;
  top: 17.6vw;
  left: 8.073vw;
  width: 38.39vw;
  height: 28.28vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 1.93vw;
}

.studio__home {
  width: 30.990vw;
  height: 11.302vw;
}

.studio__small__text {
  font-family: 'Benzin-Regular';
  line-height: 143.9%;
  font-size: 1.15vw;
}

.showreel__button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.937vw;
  height: 3.906vw;
  background-color: #DB1023;
  font-family: 'Benzin-Bold';
  font-size: 0.833vw;
  line-height: 0.990vw;
  color: #FFFFFF;
  transition: 0.5s;
}

.showreel__button:hover {
  color: #DB1023;
  background-color: #FFFFFF;

}

.test__one {
  display: none;
}

.test__two {
  display: none;
}

.test__three {
  display: none;
}

.studio__home__mobile {
  display: none;
}

.main__text__first__block__mobile {
  display: none;
}
.slick-dots{
  display: none;
}

.slick-dots li button:before {
  display: none;
}

.home__video__img__mobile {
  display: none;
}

.home__photo__img__mobile {
  display: none;
}

.home__clean__img__mobile {
  display: none;
}
.studio__big__text{
  color: #FFF;
  font-family: Benzin-ExtraBold, sans-serif;
  font-size: 3.17vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42vw;
}

@media screen and(max-width: 991px) {
  .first__block__container_image_right_background{
    display: none;
  }
  .first__block__container::after {
    display: none;
  }
  .slick-dots{
    display: block;
  }
  .slick-dots li {
    width: 5vw;
    height: 5vw;
    margin: 0 2vw;
  }

  .slick-dots {
    bottom: 5vw;
  }
  .slick-dots li button:before {
    display: block;
    font-size: 3.733vw;
    color: rgba(233, 220, 220, 0.8);
    opacity: 0.70;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.90;
    color: white;
  }
  .slick-prev {
    padding: 2vw;
    width: 5.333vh;
    height: 5.333vh;
    z-index: 5;
    left: 7vw;
    top: 104vw;
  }
  .slick-next {
    padding: 2vw;
    width: 5.333vh;
    height: 5.333vh;
    z-index: 0;
    right: 7vw;
    top: 104vw;
  }
  .slick-prev:before {
    font-family: '';
    width: 5.333vh;
    height: 5.333vh;
    content: url("~@/assets/img/slider__left__arrow.svg");
  }
  .slick-next:before {
    font-family: '';
    width: 5.333vh;
    height: 5.333vh;
    content: url("~@/assets/img/slider__right__arrow.svg");
  }
  .item__slider__container__video {
    -webkit-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.95);
    -moz-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.95);
    box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.95);


  }
  .item__slider__container__photo {
    -webkit-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);
    -moz-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);
    box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);

  }
  .item__slider__container__clean {
    -webkit-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);
    -moz-box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);
    box-shadow: inset 0px 0px 11vw 7vw rgba(0, 0, 0, 0.85);

  }
  .main__text__first__block {
    display: none;
  }
  .main__text__first__block__mobile {
    width: 73.333vw;
    height: 60.800vw;
    z-index: 4;
    position: absolute;
    bottom: 17vw;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .main__text__container__mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 40.267vw;
    width: 73.333vw;
  }
  .studio__small__text__mobile {
    font-size: 3.733vw;
    font-family: 'Benzin-Regular', sans-serif;
    line-height: 143.9%;
    color: #FFFFFF;
  }
  .showreel__button__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50.133vw;
    height: 12.533vw;
    background-color: #DB1023;
    font-family: 'Benzin-Bold', sans-serif;
    font-size: 3.733vw;
    line-height: 4.267vw;
    color: #FFFFFF;
    transition: 0.5s;
  }
  .showreel__button__mobile:hover {
    color: #DB1023;
    background-color: #FFFFFF;

  }

  .first__block__container {

    width: 100%;
    height: 216.533vw;
    background-image: none;

  }
  .home__video {
    display: none;
  }
  .home__photo {
    display: none;
  }
  .home__clean {
    display: none;
  }
  .footer__social__first__block {
    display: none;
  }
  .button__home__firs__block {
    display: none;
  }
  .img__slider {

    width: 100%;
    height: 216.533vw;
  }
  .video__text {
    display: none;
  }
  .photo__text {
    display: none;

  }
  .clean__text {
    display: none;

  }


  .test__one {
    display: block;
    position: absolute;
    top: 48vw;
    left: 41vw;
  }
  .test__two {
    display: block;
    position: absolute;
    top: 48vw;
    left: 37vw;
  }
  .test__three {
    display: block;
    position: absolute;
    top: 48vw;
    left: 32vw;
  }
  .item__slider__container__video {
    display: flex;
    align-items: center;

    font-size: 5.333vw;
    line-height: 143.9%;
    color: #FFFFFF;
    font-family: 'Benzin-ExtraBold', sans-serif;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 216.533vw;
  }
  .item__slider__container__photo {
    display: flex;
    align-items: center;


    font-size: 5.333vw;
    line-height: 143.9%;
    color: #FFFFFF;
    font-family: 'Benzin-ExtraBold', sans-serif;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 216.533vw;
  }
  .item__slider__container__clean {
    display: flex;
    align-items: center;


    font-size: 5.333vw;
    line-height: 143.9%;
    color: #FFFFFF;
    font-family: 'Benzin-ExtraBold', sans-serif;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 216.533vw;
  }

  .studio__home__mobile {
    display: block;
    width: 55.467vw;
    height: 22.667vw;
  }
  .studio__home {
    display: none;
  }
  .home__video__img__mobile {
    width: 100%;
    display: block;
  }
  .home__photo__img__mobile {
    width: 100%;
   /* transform: rotate(1deg);*/
    display: block;

  }
  .home__clean__img__mobile {
    width: 100%;
   /* transform: rotate(1deg);*/
    display: block;

  }

}
</style>
